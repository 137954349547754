<template>
  <div class="main">
    <div ref="orgOptionsline" :style="{ width: '100%', height: '100%' }"></div>
    <div class="pmana">
      <div class="mmain">
        <div
          class="main-project group-project"
          v-for="group in groups"
          :key="group.id"
        >
          <div
            class="mmpro pall"
            :class="{ 'project-checked-color': selectedGroupId == group.id }"
            @click="toggleProject(group.id)"
          >
            <span class="project-name">{{ group.name }}</span>
          </div>
        </div>
        <div v-if="selectVisiable">
          <el-select
            :popper-append-to-body="false"
            v-model="zdName2"
            clearable
            @change="getChangeRenion"
            class="el_select"
            placeholder="请选择地区"
            style="width: 280px"
          >
            <el-option
              v-for="item in seriesData"
              :key="item.id"
              :label="item.name"
              :value="item.province"
            ></el-option>
          </el-select>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { provinceMap, xjLngMapPoint } from "../../api/index";
import { debounce } from "lodash";

import eventBus from "@/utils/eventBus.js";
export default {
  name: "",
  data() {
    return {
      myChart: null,
      selectedGroupId: "1001",
      selectVisiable: false,
      zdName2: "",
      groups: [
        { id: "1001", name: "站点总体情况", desc: "xj" },
        { id: "1002", name: "站点详细情况", desc: "online" },
      ],
      seriesData: [],
    };
  },
  props: {},
  components: {},
  mounted() {
    this.getData();
    window.addEventListener("resize", this.chartResize());

  },
  methods: {
    getData() {
      xjLngMapPoint()
        .then((res) => {
          let seriesData = [];
          if (res.data !== undefined && res.data.length > 0) {
            res.data.forEach((v, index) => {
              seriesData.push({
                id: v.site_transformer_id,
                province: v.city_level,
                name: v.site_name,
                value: [v.longitude, v.latitude, 440],
              });
            });
            this.initMap("1003", "china");
            this.seriesData = seriesData;
            console.log(this.seriesData, "拿到的地图数据");
          }
        })
        .catch((error) => {
          this.$message.error(error);
        });
    },
    chartResize() {
      console.log(this.myChart,'  this.myChart');
      if (this.myChart) {
        this.myChart.resize()
      }
    },
    initMap(id, val) {
      let that = this;
      if (this.myChart) {
        this.myChart.clear();
        this.myChart.dispose();
        this.myChart = null;
      }
      this.myChart = this.$echarts.init(this.$refs.orgOptionsline);

      let myChart = this.myChart;
      let option;
      provinceMap("深圳市").then((svg) => {
        this.$echarts.registerMap("tieling", svg);
        option = {
          backgroundColor: "transparent",
          tooltip: {},
          geo: {
            tooltip: {
              show: true,
            },
            zoom: 1.2,
            label: {
              //图形上的文本标签，可用于说明图形的一些数据信息
              show: true, //是否显示文本
              color: "#BCB5B5", //文本颜色
            },
            itemStyle: {
              //地图区域的多边形 图形样式。 默认样试。
              // areaColor: "#130f40", //地图区域的颜色。
              areaColor: {
                type: "linear",
                x: 0,
                y: 0,
                x2: 0,
                y2: 1,
                colorStops: [
                  {
                    offset: 0,
                    color: "#29779D", // 0% 处的颜色
                  },
                  {
                    offset: 1,
                    color: "#103665", // 100% 处的颜色
                  },
                ],
                global: false, // 缺省为 false
              },
              borderColor: "#14F0E3", //边框线
              borderWidth: 1,
            },
            emphasis: {
              //高亮状态下的多边形和标签样式。
              label: {
                //文本
                color: "#ada",
              },
              itemStyle: {
                //区域
                areaColor: "#F60",
              },
            },
            visualMap: {
              min: 0, //最小值
              max: 600, //最大值
              calculable: true, //是否显示拖拽用的手柄（手柄能拖拽调整选中范围）。
              inRange: {
                color: ["#e83e5e", "#fb2a08", "#d6f628"], //颜色
              },
              textStyle: {
                color: "#fff",
              },
            },
            map: "tieling",
            roam: true,
          },
          series: {
            type: "effectScatter", //样试
            coordinateSystem: "geo", //该系列使用的坐标系
            data: this.seriesData,
            itemStyle: {
              //样试。
              normal: {
                //默认样试
                color: "#d6f628",
              },
            },
            label: {
              normal: {
                formatter: "{b}",
                position: "right",
                show: true,
              },
            },
            //标记的大小,可以设置数组或者函数返回值的形式
            symbolSize: function (val) {
              return val[2] / 25;
            },
            rippleEffect: {
              //涟漪特效相关配置。
              brushType: "stroke", //波纹的绘制方式
            },
            hoverAnimation: true, //鼠标移入放大圆
          },
        };
        myChart.setOption(option, true);
        myChart.on("click", function (params) {
          // params.name 即为点击的地区名称
          let seriesData = that.seriesData.filter(
            (item) => item.name == params.name
          );
          // return
          if (seriesData.length > 0) {
            eventBus.$emit("message", {
              id: that.findIndexOfMenuNamed(
                JSON.parse(sessionStorage.getItem("permissions")),
                "/equipment"
              ),
              path: {
                path: "/equipment",
                query: {
                  stationName: seriesData[0].name,
                  stationId: seriesData[0].id,
                },
              },
            });
            sessionStorage.setItem("stationId", seriesData[0].id);
          }
        });
      });
    },
    // 查找station在数组中的下标
    findIndexOfMenuNamed(array, attributeName) {
      const filteredMenuArray = array.filter(
        (item) => item.attribute === "menu"
      );
      for (let i = 0; i < filteredMenuArray.length; i++) {
        if (filteredMenuArray[i].remarks === attributeName) {
          return i; // 返回在过滤后的子集中的索引
        }
      }
      return -1; // 如果没有找到，返回-1
    },
    toggleProject(id) {
      this.selectedGroupId = id;
      if (id === "1002") {
        // 选择框显示
        this.selectVisiable = true;
      } else if (id === "1001") {
        this.selectVisiable = false;
        xjLngMapPoint()
          .then((res) => {
            let seriesData = [];
            if (res.data !== undefined && res.data.length > 0) {
              res.data.forEach((v) => {
                seriesData.push({
                  name: v.site_name,
                  value: [v.longitude, v.latitude, 440],
                });
              });
              this.seriesData = seriesData;
              this.initMap(id, "china");
            }
          })
          .catch((error) => {
            this.$message.error(error);
          });
      }
    },
    getChangeRenion(val) {
      console.log(val);
      this.initMap("1002", val);
    },
    onNavChange(nav) {
      this.$router.push(nav.path);
    },
  },
  beforeDestroy() {
    // 实例销毁前 清空实例
    if (!this.myChart) {
      return;
    }
    if (this.myChart) {
      this.myChart.clear();
    }
    window.removeEventListener("resize", this.resizeHandler);
    this.myChart.dispose();
    this.myChart = null;
  },
};
</script>

<style lang="scss" scoped>
//   .box{
//     margin-top:30px;
// }
.main {
  margin: auto;
  width: 100%;
  height: 11rem;
}
.pmana {
  display: flex;
  top: 10px;
  margin-left: 1rem;
  position: absolute;
  z-index: 99;
  color: #fff;
  font-size: 14px;

  .mmain {
    display: flex;
    //   width: 100%;
    font-size: 14px;

    &.draging {
      background: rgba(36, 127, 255, 0.3);
    }
  }

  .mmpro {
    line-height: 36px;
    height: 45px;
    width: 3.5rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-right: 20px;
    a {
      padding-left: 20px;
      text-decoration: none;
      color: #fff;
      width: 150px;
    }
  }

  .pall {
    padding-left: 25px;
    transition: color 0.2s;
    cursor: pointer;
    border-radius: 5px;
    background-image: url("../../assets/images/index/topUnselect.png");
    background-repeat: round;
    //   width: 100%;

    &:hover {
      color: #2681ff;
    }
  }

  .project-checked-color {
    background-image: url("../../assets/images/index/topSelect.png");
    background-repeat: round;
    //   width: 100%;

    &:hover {
      color: #fff !important;
    }

    .project-name {
      color: #fff !important;
    }
  }

  .project-num {
    user-select: none;
    color: #bcc9d4;
  }

  .group-btns {
    display: none;
    color: #2681ff;

    i + i {
      margin-left: 10px;
    }
  }

  .main-project {
    position: relative;
    height: 36px;
    display: flex;
    font-size: 12px;
    margin-top: 5px;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    transition: color 0.2s;

    .project-name {
      width: 100px;
      font-size: 14px;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      pointer-events: none;
    }

    &:hover {
      .project-name {
        color: #2681ff;
      }
    }

    &.drag-enter {
      background: #171b22;
    }
  }

  .group-project {
    &:hover {
      .project-num {
        display: none;
      }

      .group-btns {
        display: inline-block;
      }
    }
  }
}
::v-deep .el-input__inner {
  background: #171b22;
  border: 1px solid #215de9;
  color: #fff;
}
::v-deep .el-select {
  background: #171b22;
  color: #fff;
}
::v-deep .el-select_wrapper {
  background: #171b22;
  border: 1px solid #215de9;
  color: #fff;
}
::v-deep .el-select-dropdown {
  background: #171b22;
  border: 1px solid #215de9;
  color: #fff;
}
</style>
