<template>
  <div id="index">
    <dv-loading v-if="loading">Loading...</dv-loading>
      <div v-else class="host-body">
        <div class="body-box">
          <div class="content-box-1">
            <div class="first-column">
            <div class="first-column-1">
              <!--状态检测任务统计-->
              <dv-border-box-12 :key="`${resizeKey}fourth`">
                <fourth />
              </dv-border-box-12>
            </div>
            <div>
              <!--告警情况统计-->
              <dv-border-box-13 :key="`${resizeKey}centerLeft1`">
                <centerLeft1 />
              </dv-border-box-13>
             </div>
            </div>
            <div class="second-column">
              <!--图片-->
              <dv-border-box-8 :key="`${resizeKey}map`">
                <echarts-bmap-tool></echarts-bmap-tool>
              </dv-border-box-8>
            </div>
            <div class="third-column">
            <div class="third-column-1">
              <!--带电检测数据统计-->
              <dv-border-box-12 :key="`${resizeKey}third`">
                <third />
              </dv-border-box-12>
            </div>
            <div class="third-column-2">
              <dv-border-box-13 :key="`${resizeKey}centerLeft2`">
                <centerLeft2 />
              </dv-border-box-13>
            </div>
            </div>
          </div>
        <!-- <div class="content-box-1">
        </div> -->
      </div>
      </div>
  </div>
</template>

<script>
import centerLeft1 from './components/centerLeft1.vue' // 左上 
import centerLeft2 from './components/centerLeft2.vue' // 左下
import third from './components/third.vue'  // 右上
import fourth from './components/fourth.vue' // 右下
import echartsBmapTool from '../../components/tools/echartsBmapTool.vue' // 地图
export default {
  data () {
    return {
      loading: true,
      resizeKey:0,
      // dateDay: null,
      // dateYear: null,
      // dateWeek: null,
      weekday: ['周日', '周一', '周二', '周三', '周四', '周五', '周六'],
      selectedGroupId: '1001',
      groups: [{id: '1001', name: '今日', desc: 'today'},{id: '1002', name: '本周', desc: 'week'},{id: '1003', name: '本月', desc: 'month'},{id: '1004', name: '累计', desc: 'total'}]
    }
  },
  components: {
    centerLeft1,
    centerLeft2,
    fourth,
    third,
    echartsBmapTool
  },
  mounted () {
    this.cancelLoading()
    window.addEventListener('resize', this.handleResize);
  },
  methods: {
    handleResize () {
      // 在父容器的宽高发生变化后，强制更新 key 值
      this.$nextTick(() => {
        this.resizeKey += 1; // 修改key值，触发组件重新渲染
      });
    },
    cancelLoading () {
      setTimeout(() => {
        this.loading = false
      }, 500)
    }
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.handleResize);
  }
}
</script>

<style lang="scss">
.el-tree {
    position: relative;
    cursor: default;
    background: transparent;
    color: #9ea8bd;
}
.el-tree-node__content:hover {
    background: rgba(104,147,215,0.4);
}
.el-tree-node:focus > .el-tree-node__content {
    background: rgba(104,147,215,0.4);
}
.host-body {
  .body-box {
    margin-top: 0.0001rem;
    display: flex;
    flex-direction: column;
  }
}
// 宽高
.w-100 {
  width: 100%;
}
.h-100 {
  height: 100%;
}

//flex
.d-flex {
  display: flex;
}
.flex-column {
  flex-direction: column;
}
.flex-wrap {
  flex-wrap: wrap;
}
.flex-nowrap {
  flex-wrap: nowrap;
}
.mx-2 {
    margin-left: 0.1rem;
    margin-right: 0.1rem;
}
.fs-xl {
    font-size: 0.04rem;
}
.pt-2 {
    padding-top: 0.1rem;
}
.colorBlue {
    color: #ffba25b9 !important;
}
.ullist{
  color: aliceblue;
  margin-left: 0.2rem;
  .item{
    color: azure;
    margin-left: 0.2rem;
    margin-top: 0.2rem;
  }
}
@import '../../assets/css/index.scss'

</style>
