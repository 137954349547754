<template>
  <div id="center">
    <div class="bg-color-black">
      <div class="d-flex pt-2 pl-2">
        <span style="color: #5cd9e8">
          <icon name="chart-bar"></icon>
        </span>
        <div class="d-flex">
          <span class="fs-xl text mx-2">监测设备统计</span>
          <dv-decoration-3
            style="
              width: 1.25rem;
              height: 0.25rem;
              position: relative;
              top: -0.0375rem;
            "
          />
        </div>
      </div>
      <div class="down">
        <div class="project-manage2">
          <div
            style="display: flex; justify-content: space-between"
            v-for="item in typeList"
            :key="item.type"
          >
            <template>
              <el-image
                style="width: 50px; line-height: 10px"
                :src="item.src"
              ></el-image>
              <span style="color: #eee; font-size: 18px; margin-top: 10px">{{
                item.type
              }}</span>
            </template>
            <dv-digital-flop
              :config="{ number: [item.count], content: '{nt}个' }"
              style="height: 30px; margin-top: 10px"
              :key="item.type"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getSensorCount, getDeviceCount } from "@/api/index";
export default {
  data() {
    return {
      config: {
        number: [100],
        content: "{nt}个",
      },
      typeList: [],
    };
  },
  components: {},
  mounted() {
    this.initData();
  },
  methods: {
    initData() {
      // 请求数量
      let arr = [];
      // getSensorCount()
      //   .then((res) => {
      //     if (res.code == 200) {
      //       console.log(res.data, "变压器数据");
      //       res.data.forEach((v) => {
      //         if (v.collection_terminal_type == "jf3in1") {
      //           arr.push({
      //             type: "10kV干式变压器",
      //             count: v.count,
      //             src: require("../../../assets/images/transformer.png"),
      //           });
      //         } else if (v.collection_terminal_type == "tem") {
      //           arr.push({
      //             type: "6.6kV干式变压器",
      //             count: v.count,
      //             src: require("../../../assets/images/transformer.png"),
      //           });
      //         } else if (v.collection_terminal_type == "vibra") {
      //           arr.push({
      //             type: "10kV电缆",
      //             count: v.count,
      //             src: require("../../../assets/images/cable.png"),
      //           });
      //         }
      //       });
      //     }
      //   })
      //   .catch((err) => {
      //     console.log(err);
      //   });
      getDeviceCount()
        .then((res) => {
          if (res.code == 200) {
            res.data.forEach((v) => {
              if(v.equipment_type == "变压器"){
                arr.push({
                  type: "变压器",
                  count: v.amount,
                  src: require("../../../assets/images/transformer.png")
                })
              } else
              if (v.equipment_type == "开关柜") {
                arr.push({
                  type: "开关柜",
                  count: v.amount,
                  src: require("../../../assets/images/cabinet.png"),
                });
              }
              else if (v.equipment_type == "电缆") {
                arr.push({
                  type: "电缆",
                  count: v.amount,
                  src: require("../../../assets/images/cable.png"),
                });
              }
            });
          }
        })
        .catch((err) => {
          console.log(err);
        });
      this.typeList = arr;
    },
  },
};
</script>

<style lang="scss" scoped>
#center {
  display: flex;
  flex-direction: column;
  .bg-color-black {
    margin-top: 0.1rem;
    margin-left: 0.1rem;
    height: 8.8rem;
    border-radius: 0.125rem;
  }
  .text {
    color: #c3cbde;
    font-size: 18px;
  }
  .chart-box {
    margin-top: 0.2rem;
    width: 2.125rem;
    height: 2.125rem;
    .active-ring-name {
      padding-top: 0.125rem;
    }
  }
  .down {
    padding: 0.07rem 0.05rem;
    padding-bottom: 0;
    width: 100%;
    box-sizing: border-box;
  }
  .project-manage2 {
    color: #fff;
    font-size: 14px;
    overflow-y: auto;
    height: 100%;
  }
}
</style>
