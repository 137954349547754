<template>
  <div id="center">
    <div class="bg-color-black">
      <div class="d-flex pt-2 pl-2">
        <span style="color: #5cd9e8">
          <icon name="chart-bar"></icon>
        </span>
        <div class="d-flex">
          <span class="fs-xl text mx-2">点数统计</span>
          <dv-decoration-3
            style="
              width: 1.25rem;
              height: 0.25rem;
              position: relative;
              top: -0.0375rem;
            "
          />
        </div>
      </div>
      <div class="down">
        <div class="project-manage2">
          <div class="flex-item">
            <div class="item-1">
              <div >站点总数</div>
              <div style="font-size: 20px;height: 30px;">{{ totalA }}</div>
            </div>
            <div class="item-1">
              <div >在线点数</div>
              <div style="font-size: 20px;height: 30px;">{{ zax }}</div>
              
            </div>
            <div class="item-1">
              <div >离线点数</div>
              <div style="font-size: 20px;height: 30px;">{{ lix }}</div>
              
            </div>
          </div>
          <div class="float-chart">
            <chart :data="data" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getSitesState } from "@/api/index";
import chart from "./bottom/yibiaoChart/chart.vue";
export default {
  components: {
    chart,
  },
  data() {
    return {
      totalA: 0,
      zax: 0,
      lix: 0,
      data: 100,
    };
  },
  computed: {
    configTotal() {
      return {
        number: [this.totalA],
        content: "{nt}",
        style: {
          fontSize: 30,
          fill: "#3de7c9",
        },
      };
    },
    configTotalZX() {
      return {
        number: [this.zax],
        content: "{nt}",
        style: {
          fontSize: 30,
          fill: "#3de7c9",
        },
      };
    },
    configTotalLiX() {
      return {
        number: [this.lix],
        content: "{nt}",
        style: {
          fontSize: 20,
          fill: "#3de7c9",
        },
      };
    },
  },
  mounted() {
    this.initData();
  },
  methods: {
    initData() {
      getSitesState().then((res) => {
        if (res.code == 200) {
          this.totalA = res.st_count;
          this.zax = res.st_on;
          this.lix = res.st_off;
          this.data =
            res.st_count == 0
              ? "100"
              : Math.floor((res.st_on / res.st_count) * 100 + "");
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
#center {
  display: flex;
  flex-direction: column;
  .bg-color-black {
    margin-top: 0.1rem;
    margin-left: 0.1rem;
    height: 8.8rem;
    border-radius: 0.125rem;
  }
  .text {
    color: #f7f7f7;
    font-size: 18px;
  }
  .down {
    padding: 0.07rem 0.05rem;
    padding-bottom: 0;
    width: 100%;
    display: flex;
    justify-content: space-around;
    // .ranking {
    //   padding: 0.125rem;
    //   width: 59%;
    // }
  }
  .project-manage2 {
    width: 100%;
    width: 100%;
    margin-left: 10px;
    font-size: 14px;
    height: 100%;
    .flex-item {
      display: flex;
      .item-1 {
        flex: 1;
        color: #05effe;
         font-size: 25px;
         text-align: center;
      }
    }
    .float-chart {
      height: 4.5rem;
    }
  }
}
.fs-xw {
  color: rgb(34, 170, 163);
  margin-left: 0.2rem;
}
</style>
