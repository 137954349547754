<template>
  <div>
    <Echart
      :options="options"
      id="yibiaoChart"
      height="4.5rem"
      width="100%"
    ></Echart>
  </div>
</template>

<script>
import Echart from "@/common/echart";
export default {
  name: "chart",
  data() {
    return {
      options: {},
    };
  },
  components: {
    Echart,
  },
  props: {
    data: {
      type: Number,
      default: 100,
    },
  },
  watch: {
    data: {
      handler(newData) {
        this.options = {
          // 鼠标悬浮的提示
          series: [
            {
              type: "gauge",
              startAngle: 180,
              endAngle: 0,
              radius: 180,
              min: 0,
              max: 100,
              splitNumber: 10,
              itemStyle: {
                color: "#58D9F9",
                shadowColor: "rgba(0,138,255,0.45)",
                shadowBlur: 10,
                shadowOffsetX: 2,
                shadowOffsetY: 2,
              },
              progress: {
                show: true,
                roundCap: true,
                width: 18,
              },
              pointer: {
                icon: "path://M2090.36389,615.30999 L2090.36389,615.30999 C2091.48372,615.30999 2092.40383,616.194028 2092.44859,617.312956 L2096.90698,728.755929 C2097.05155,732.369577 2094.2393,735.416212 2090.62566,735.56078 C2090.53845,735.564269 2090.45117,735.566014 2090.36389,735.566014 L2090.36389,735.566014 C2086.74736,735.566014 2083.81557,732.63423 2083.81557,729.017692 C2083.81557,728.930412 2083.81732,728.84314 2083.82081,728.755929 L2088.2792,617.312956 C2088.32396,616.194028 2089.24407,615.30999 2090.36389,615.30999 Z",
                length: "75%",
                width: 10,
                offsetCenter: [0, "5%"],
              },
              axisLine: {
                roundCap: true,
                lineStyle: {
                  width: 10,
                },
              },
              axisTick: {
                splitNumber: 2,
                lineStyle: {
                  width: 2,
                  color: "#58D9F9",
                },
              },
              splitLine: {
                length: 12,
                lineStyle: {
                  width: 3,
                  color: "#eeeeee",
                },
              },
              axisLabel: {
                distance: 10,
                color: "#eee",
                fontSize: 20,
              },
              title: {
                show: false,
              },
              detail: {
                width: "100%",
                lineHeight: 40,
                height: 40,
                borderRadius: 10,
                offsetCenter: [0, "35%"],
                valueAnimation: true,
                formatter: function (value) {
                  return "站点在线率{value|" + value.toFixed(0) + "%}";
                },
                rich: {
                  value: {
                    fontSize: 25,
                    fontWeight: "bolder",
                    color: "#fff200",
                  },
                
                },
              },
              data: [
                {
                  value: newData,
                },
              ],
            },
          ],
        };
      },
      immediate: true,
      deep: true,
    },
  },
};
</script>
