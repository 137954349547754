<!--状态检测任务统计 左上角显示-->
<template>
  <div id="centerLeft1">
    <div class="bg-color-black">
      <div class="d-flex pt-2 pl-2">
        <span style="color: #5cd9e8">
          <icon name="chart-bar"></icon>
        </span>
        <div class="d-flex">
          <span class="fs-xl text mx-2">最新预警数据</span>
          <dv-decoration-3
            style="
              width: 1.25rem;
              height: 0.25rem;
              position: relative;
              top: -0.0375rem;
            "
          />
        </div>
      </div>
      <div class="d-flex jc-center">
        <dv-scroll-board
          :config="config"
          style="
            margin-left: 0.1rem;
            padding-top: 0.2rem;
            width: 100%;
            height: 3.5rem;
          "
        ></dv-scroll-board>
      </div>
      <div class="total">共{{ total }}条</div>

    </div>
  </div>
</template>

<script>
import { getYujingData } from "@/api/index";
export default {
  data() {
    return {
      total: 10,
      resizeKey: 0,
      yujingData: [
        ['<span style="color:#37a2da;">行1列1</span>', "行1列2", "1", "告警"],
        ["行2列1", '<span style="color:#32c5e9;">行2列2</span>', "1", "告警"],
        ["行3列1", "行3列2", "1", '<span style="color:#FF0000;">告警</span>'],
        ["行4列1", '<span style="color:#9fe6b8;">行4列2</span>', "1", "告警"],
        ['<span style="color:#ffdb5c;">行5列1</span>', "行5列2", "1", "告警"],
        ["行6列1", '<span style="color:#ff9f7f;">行6列2</span>', "1", "告警"],
        ["行7列1", "行7列2", "1", '<span style="color:#FF0000;">告警</span>'],
        ["行8列1", '<span style="color:#e062ae;">行8列2</span>', "1", "告警"],
        ['<span style="color:#e690d1;">行9列1</span>', "行9列2", "1", "告警"],
        ["行10列1", '<span style="color:#e7bcf3;">行10列2</span>', "1", "告警"],
      ],
    };
  },
  computed: {
    config() {
      return {
        header: ["时间", "设备名称", "位置", "状态"],
        indexHeader: "序号",
        rowNum: 5, // 表行数
        headerBGC: "#FF661c", // 表头背景色
        oddRowBGC: "#9fe6b834", // 奇数行背景色
        evenRowBGC: "#37a2da33", // 偶数行背景色
        headerHeight: 50,
        waitTime: 2000, // 轮播时间间隔(ms)
        align: ["center"],
        data: this.yujingData,
      };
    },
  },
  mounted() {
    this.setData();
    window.addEventListener('resize', this.handleResize);

  },
  beforeDestroy() {
    window.removeEventListener('resize', this.handleResize);
  },
  methods: {
    handleResize () {
      // 在父容器的宽高发生变化后，强制更新 key 值
      this.$nextTick(() => {
        this.resizeKey += 4; // 修改key值，触发组件重新渲染
      });
    },
    setData() {
      getYujingData().then((res) => {
        this.yujingData = [];
        if (res.code == 200) {
          this.config.data = [];
          res.data.forEach((v) => {
            let arr = [];
            if (v.early_warning == "2") {
              arr.push(
                `<span style="color:#FFC90E;">${v.establish_time.replace("T", " ").slice(5, -3)}</span>`,
                `<span style="color:#FFC90E;">${v.equipment_name}</span>`,
                `<span style="color:#FFC90E;">${v.site_name}</span>`,
                `<span style="color:#FFC90E;">关注</span>`
              );
            } else {
              arr.push(
                `${v.establish_time.replace("T", " ").slice(5, -3)}`,
                `${v.equipment_name}`,
                `${v.site_name}`,
                `<span style="color:#FF0000;">预警</span>`
              );
            }

            this.yujingData.push(arr);
          });
        }
        this.total = this.yujingData.length
      });
    },
  },
  beforeDestroy() {},
};
</script>

<style scoped lang="scss">
#centerLeft1 {
  padding: 0.2rem;
  height: 6.125rem;
  min-width: 3.75rem;
  border-radius: 0.0625rem;
  .bg-color-black {
    height: 5.8125rem;
    border-radius: 0.125rem;
  }
  .text {
    color: #c3cbde;
    font-size: 18px;
  }
  .project-manage {
    min-width: 100px;
    max-width: 100px;
    color: #fff;
    position: sticky;
    top: 70px;
    font-size: 14px;
    overflow-y: auto;
    height: 100%;

    .manage-main {
      display: flex;
      flex-direction: column;
      font-size: 14px;

      &.draging {
        background: rgba(36, 127, 255, 0.3);
      }
    }

    .project-num {
      user-select: none;
      color: #bcc9d4;
    }

    .group-btns {
      display: none;
      color: #2681ff;

      i + i {
        margin-left: 10px;
      }
    }

    .main-project {
      position: relative;
      height: 36px;
      display: flex;
      font-size: 12px;
      margin-top: 5px;
      align-items: center;
      justify-content: space-between;
      cursor: pointer;
      transition: color 0.2s;

      .project-name {
        width: 100px;
        font-size: 14px;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        pointer-events: none;
      }

      &:hover {
        .project-name {
          color: #2681ff;
        }
      }

      &.drag-enter {
        background: #171b22;
      }
    }

    .group-project {
      &:hover {
        .project-num {
          display: none;
        }

        .group-btns {
          display: inline-block;
        }
      }
    }
  }
  .project-manage2 {
    min-width: 70%;
    max-width: 70%;
    color: #fff;
    position: sticky;
    margin-left: 10px;
    top: 70px;
    font-size: 14px;
    overflow-y: auto;
    height: 100%;
    // text-orientation: upright;
    // /*文字禁止编辑*/
    // -moz-user-select: none; /*火狐*/
    // -webkit-user-select: none; /*webkit浏览器*/
    // -ms-user-select: none; /*IE10*/
    // -khtml-user-select: none; /*早期浏览器*/
    // user-select: none;
    .content_title {
      color: #c3cbde;
      font-size: 20px;
      // display: flex;
      margin-left: 20px;
      margin-top: 30px;
      flex-direction: column;
    }
    .number {
      display: inline;
    }
  }
  .total {
    height: 20px;
    font-size: 18px;
    margin-top: 20px;
    color: #fff;
    margin-right: 0;
  }
}
</style>
